/* layout - sticky footer mode */
html {
  position: relative;
  min-height: 100%;
  margin: 0;
  padding: 0;
}
body {
  margin-bottom: 420px !important;
  font-family: Roboto, Geneva, 'Lucida Console', sans-serif;
}
.header {
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.content {
  padding-top: 20px;
  padding-bottom: 20px;
  padding-left: 0;
  padding-right: 0;
}
.footer {
  position: absolute;
  bottom: 0;
  height: 420px !important;
  width: 100%;
  padding-left: 0;
  padding-right: 0;
}
.main-footer {
  background-color:#1e252d;
  color: #666;
}
.book-promotion {
  background-color: #ff1493;
  color: #f5f5f5;
  font-weight: 700;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 10px;
  text-align: center;
}
/* https://gist.github.com/CodeMyUI/f9522105eb8d583ef5697c58a2130da0 */
.strikethrough {
  position: relative;
}
.strikethrough:before {
  position: absolute;
  content: "";
  left: 0;
  top: 40%;
  right: 0;
  border-top: 4px solid #dd0031!important;
  border-color: inherit;

  -webkit-transform:rotate(-30deg);
  -moz-transform:rotate(-30deg);
  -ms-transform:rotate(-30deg);
  -o-transform:rotate(-30deg);
  transform:rotate(-30deg);
}

/* header nav */
.navbar {
  padding-left: 0 !important;
  padding-right: 0 !important;
  padding-bottom: 5px !important;
  padding-top: 5px !important;
}
.navbar-brand {
  padding-left: 0 !important;
}
.navbar-light {
  background-color: #fff !important;
}
.navbar-light .nav-link {
  color: #777 !important;
  font-weight:700;
	padding:10px 15px;
}
.navbar-light .active > .nav-link {
  color: #dc143c !important;
  font-weight:700;
	padding:10px 15px;
}
.navbar-light .nav-btn a {
  border: 1px solid #2abf46;
  border-radius: 6px;
  padding: 10px 33px !important;
  font-weight: 700;
	display:block;
	color:#fff !important;
	background-color:#2abf46;
}
.navbar-light .dropdown-menu-right {
	margin-top: -10px !important;	
}

/* footer */
.social-sec h3 {
	font-weight:700;
	font-size:30px;
	color:#fff;
}
.social-sec ul {
	display:table;
	margin:0 auto;
	padding:0;
}
.social-sec ul li {
	float:left;
	margin:15px 12px 0;
	list-style:none;
}
.social-sec ul li a {
  display: block;
  width: 48px;
  height: 48px;
  background-color: #fff;
  border-radius: 50%;
  border: 1px solid #fff;
  color: #1e242e;
}
.social-sec ul li a i {
	font-size:30px;
	line-height:48px;
}
.ft-head {
  font-size: 20px;
  font-weight:700;
  margin-bottom: 15px;
  margin-top:0;
  color: #fff;
}
.ft-ul {
	margin:0;
  padding:0;
  color:#b0c4de !important;
}
.ft-ul .active {
  color: #dc143c !important;
}
.ft-ul li {
	list-style:none;
}
.ft-ul li a {
  cursor: pointer;
  font-size: 15px;
  line-height: 1.8;
  color:inherit !important;
}
.copyright {
  border-top: 1px solid #36393e;
  color:#faf0e6;
  margin-top: 15px;
  padding-top: 15px;
}

input[type="checkbox"]:disabled ~span {
  color:#e2e2e2 !important;
}

/* https://codeconvey.com/css-overlay-image-over-image */
.overlay-container {
  position: relative;
  display:inline-block;
}
.overlay-title {
  position: absolute;
  top: 50%;
  left: 50%;
}

/* #####media queries#### */
/* till extra small */
@media (max-width:575px) {
  .navbar-brand>img {
    max-width: 100px !important;
  }
  .social-sec h3 {
    font-size:24px;
  }
  .footer-links {
    text-align: center !important;
  }
  .coll-cust-hdr-i {
    max-width: 100px !important;
  }
  .coll-cust-hdr-t {
    font-size: 24px;
    overflow-wrap: break-word;        
  }

  /* bs4 */
  .input-group-prepend {
    display: block;
    margin-bottom: 10px;
    clear: both;
  }
  .input-group {
    display: block;
    width: '100%' !important;
    border: '1px solid red' !important;
  }
  .igp-fc {
    display: block;
    width: 100% !important;
  }
}

/* small to medium */
@media (min-width:576px) and (max-width:767px) {
  .navbar-brand>img {
    max-width: 130px !important;
  }
}
@media (min-width:768px) and (max-width:991px) {
  .navbar-brand>img {
    max-width: 150px !important;
  }
}
/* till medium */
@media (max-width:991px) {
  .navbar-brand {
    margin-right: 0 !important;
  }
  .navbar-light .navbar-toggler {
    border: none !important;
    padding-left: 0;
    padding-right: 0;
  }
  .navbar-light .navbar-toggler {
    background-color:#0c132e;
	  border:1px solid #0c132e;
    border-radius:5px;
    font-size:14px;
    padding: 10px 5px 10px 5px;
	  color:#fff !important;
	  font-weight:700;
    outline: none;
  }
  .navbar-light .navbar-nav {
    background-color: #0c132e;
	}
	.navbar-light .navbar-nav .dropdown-menu {
    background-color: #0c132e;
    color:#fff !important;
  }
  .navbar-light .navbar-nav .dropdown-item {
    color:#fff !important;
  }
  .navbar-light .nav-link {
    color:#fff !important;
    text-align: left;
  }
  .navbar-light .nav-btn a {
    border-radius:5px;
    font-size:16px;
    padding: 5px 10px 5px 10px !important;
	  color:#fff !important;
	  font-weight:700;
  }
  .navbar-light .navbar-collapse {
    margin-top: -1px;
  }
  .college-header span, .college-header a {
    font-size: 20px;
  }
}
/* #####media queries#### */