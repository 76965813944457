.colleges-sec {
	position:relative;	
	background-repeat:no-repeat;
}
.colleges-sec-bg-1 {
  background-image:url(../images/colleges-bg.jpg);
  background-size:cover;	
	padding: 0 !important;
	margin-top: -20px !important;
}
.colleges-sec-bg-1-t {	
  width:100%;
	height:100%;
	background-color:rgba(30,36,46,0.6);
	padding:130px 0;
}
.colleges-sec h2 {
	font-size:45px;
	margin-top:0;
	margin-bottom:20px;
	line-height:1.3;
	font-weight:700;
	color:#1e242e;
}
.colleges-sec h3 {
	color:#fff;
	font-weight:700;
	font-size:45px;
}
.colleges-sec h4 span {
  color:#2abf46;
}
.colleges-sec-bg-2 {
	background-color:#f9f9f9;
	padding:40px 0;	
}
.colleges-sec-bg-3 {
  background-color:#f5f5f5;	
  padding:40px 0;
}
.colleges-sec-detail-1 p {
  font-weight: 700;
}
.colleges-sec-detail-1 ul  {
	margin:0;
	padding:0;	
}
.colleges-sec-detail-1 ul li {
	list-style:none;
	font-size:16px;	
	color:#666666;
	padding:5px 0;
	display:inline-block;
	position:relative;
	padding-left:40px;	
	line-height:1.5;
}
.colleges-sec-detail-1 ul li:after {
	position:absolute;
	content:"";
	top:10px;
	left:0;
	width:15px;
	height:15px;
	border:2px solid #2abf46;
	border-radius:50%;
}
.colleges-sec-detail-2 {
  padding: 20px;
  background-color: #fff;
}
.colleges-sec-detail-2 p {
  padding: 0;
  color: #2abf46;
  font-weight: 700;
}
.colleges-sec-detail-2 ul {
  list-style:none;
}
.colleges-sec-detail-2 ul li {
	margin-bottom:10px;
	font-size:16px;
	color:#666;
	position:relative;
	padding-left:25px;
	display:inline-block;
	line-height:1.5;
}
.colleges-sec-detail-2 ul li i {
	position:absolute;
	top:5px;
	left:0;	
	color:#2abf46;
}
.colleges-sec-detail-3 h2 {
  font-size:20px;
}

/* #####media queries#### */
/* till small */
@media (max-width:767px) {
	.colleges-sec {	
		background-position: 70% 0%;
	}
}
/* #####media queries#### */
