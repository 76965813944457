.main_color{
	background-color: #f5f5f5;
}
.regular_color{
	background-color: #ffffff;
}
.top_p{
	padding-top: 100px;
	padding-bottom: 50px;
}
.student_name{
	background-color: #fff;
	position: relative;
	padding: 20px;
}
.student_i{
	max-width: 300px;
	max-height: 300px;
}
.heding_n{
	border-bottom: solid 1px #eeeeee;
	margin-top: 55px;
	padding-bottom: 10px;
}
.heding_n_1{
	border-bottom: solid 1px #eeeeee;
	margin-top: 15px;
	padding-bottom: 10px;
}
.name_s{
	font-size: 30px;
    font-weight: 700;
    color: #252525;
    margin-bottom: 0px;
}
.add_e{
	padding-left: 25px;
}
.email_add ul{
	margin-bottom: 5px;
	padding-left: 0px;
}
.email_add ul li{
	display: inline-block;
	padding-top: 5px;
}
.email_t{
	color: #000;
	font-weight: 600;
}
.addres{
	color: #aaaaaa;
	text-decoration: none;
}
.career{
	font-size: 25px;
    font-weight: 700;
    color: #1e242e;
    margin-bottom: 5px;
    margin-top: 15px;
}
.lorem_font{
	color: #aaaaaa;
	font-size: 15px;
	line-height: 25px;
	word-spacing: 5px;
	text-align: justify;
	margin-bottom: 0px;
}
.icon_tow{
	float: left;
	padding-top: 10px;
}
.tow_i{
	padding-left: 20px;
}
.about_heding{
	margin-top: 0px;
	padding-bottom: 19px !important;
}
.video_one{
	margin-top: 25px;
    position: relative;
    background-size: cover;
    top: 0;
    right: 0;
    height: 100%;
}
.vid-b{
	border: 1px solid #E8E8E4;
}
.play_button{
	width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    cursor: pointer;
}
.toe_top{
	padding-bottom: 20px;

}
.apr_block{
	padding-top: 15px;
}
.apr_block ul li{
	display: inline-block;
}
.jul_m{
	font-size: 16px;
	color: #aaaaaa;
}
.Software{
	color: #2abf46;
	font-size: 20px;
}
.Software p{
	font-size: 16px;
	color: #000;
}

.p_g, .p_d{
	font-size: 16px;
	color: #aaaaaa;
	line-height: 25px;
}
.p_d{
	padding-top: 15px;
	 list-style-type: circle;
	 position: relative;
	 padding-left: 15px;
}
.p_d:before{
	content: "";
	width: 6px;
	height:6px;
	background-color: #2abf46 !important;
	border-radius: 50px;
	position: absolute;
	top: 25px;
	left:0;
}
.bullet{
	position: relative;
}
.p_t_p{
	padding-top: 15px;
}
.bullet:before{
	content: "";
	width: 10px;
	height: 10px;
	border: solid 2px #bbbbbb;
	border-radius: 50px;
	position: absolute;
    top: 13px;
    left: -9px;
    transform: translate(-50%,-50%);
}
.p-d{
	word-spacing: 9px;
}
.btech{
	text-transform: uppercase;
	color: #000;
	font-size: 15px;
	font-weight: bold;
}
.linking{
	color: #2abf46;
    font-size: 20px;
}
.siw{
	font-size: 16px;
	color:#000;
}
.gpa{
	font-size: 16px;
	color:#000;
	padding-top: 10px;
	font-weight: bold;
}
.gpa span{
	font-size: 16px;
	color:#aaaaaa;
	padding-left: 3px;
}
.bullet-tow{
	position: relative;
}
.bullet-tow:before{
	content: "";
    width: 10px;
    height: 10px;
    border: solid 2px #bbbbbb;
    border-radius: 50px;
    position: absolute;
    top: 16px;
    left: -9px;
    transform: translate(-50%,-50%);
}
.add-info{
	font-size: 15px;
	color:#000;
	padding-left:0px;
	margin-bottom: 30px;
}
.add-info span{
	font-size: 15px;
	color:#aaaaaa;
	padding-left: 3px;
}
.con_r{
	margin-bottom: 0px;
	padding-top: 10px;
}
.con_r:before{
	content: "";
    top: 20px;
}
.ngt_next{
	color: #000;
	text-decoration: none;
}
.one-o{
	margin-bottom: 0px;
}
.b_pd, .online-link{
	color: #2abf46;
    font-size: 20px;
}
.wordpres, .phoenix{
	font-size: 16px;
	color:#000;
}
.online-link{
  text-decoration: underline;
  -webkit-text-decoration-color: #aaaaaa;
  text-decoration-color: #aaaaaa;
}
.main_img_p{
	padding-top: 20px;
}
.image_f-l, .image_f-r{
	float: left;
}
.phoenix{
	padding-top: 5px;
}
.phoenix, .sony{
	margin-bottom: 5px;
	padding-left: 73px;

}
.sony{
	font-size: 16px;
	color:#aaaaaa;
}
.main_footer_block{
	padding-top:60px;
	padding-bottom: 0px; 
}

@media (min-width: 320px) and (max-width: 767px){
  .bullet:before, .bullet-tow:before {
    left: 10px;
  }
  .br_o{
    display: none;
  }
  .name_s {
      font-size: 25px;
  }
  .career {
      font-size: 20px;
  }
  .add_e {
      padding-left: 0px;
  }
  .b_pd, .online-link, .linking, .Software a {
      font-size: 18px;
  }
  .btech {
      font-size: 14px;
  }
  .bullet-tow, .bullet{
      padding-left: 25px;
  }
	.student_i{
		max-width: 180px;
		max-height: 180px;
	}
	.bottom-m {
		margin-bottom: 10px;
	}
}
@media (min-width: 768px) and (max-width: 991px){
  .bullet:before, .bullet-tow:before {
    left: 10px;
  }
  .br_o{
    display: none;
  }
  .name_s {
      font-size: 25px;
  }
  .b_pd, .online-link {
      font-size: 19px;
  }
  .add_e {
      padding-left: 0px;
  }
  .bullet-tow, .bullet{
      padding-left: 25px;
  }
}
@media (min-width: 992px) and (max-width: 1099px){
    .bullet:before, .bullet-tow:before {
        left: 10px;
    }	
    .name_s {
        font-size: 27px;
    }
    .b_pd, .online-link, .linking, .Software a {
        font-size: 18px;
    }
    .bullet-tow, .bullet{
        padding-left: 25px;
    }
}