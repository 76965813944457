.students-sec {
	position:relative;	
	background-repeat:no-repeat;
}
.students-sec-bg-1 {
	background-image: url(../images/student.jpg);
	background-position: center;
	padding: 0 !important;
	margin-top: -20px !important;
}
.students-sec-bg-1-t {
	width: 100%;
	height: 100%;
	background-color: rgba(30,36,46,0.6);
	padding: 130px 0;
}
.students-sec h3 {
	color:#fff;
	font-weight:700;
	font-size:45px;
}
.students-sec h4 span {
  color:#2abf46;
}
.students-sec-bg-2 {
	background-color: #f9f9f9;	
}
.students-sec-detail-1 {
	border:1px solid #dedfe1;
	padding:20px 15px;
}
.students-sec-detail-1-img {
	margin-top: -20px !important;
	margin-left: -15px !important;
	margin-right: -15px !important;
}
.students-sec-detail-1-img img {
	width: 100% !important;
}
.students-sec-detail-1 h2 {
	font-size:20px;
	font-weight:700;
	color:#000;
	margin-top:0;
	margin-bottom:15px;
}
.students-sec-detail-1 p {
	line-height:1.5;
	margin-bottom:15px;
	min-height:144px;
}
.students-sec-detail-2 .row {
	margin-top: 20px !important;	
}
.students-sec-detail-2 h3 {
	color: #000;
	font-size: 20px;	
}
.students-sec-detail-2 ul li a {
	font-size:14px;	
}
.students-sec-detail-2 ul  {
	margin:0;
	padding:0;	
}
.students-sec-detail-2 ul li {
	list-style:none;	
}
.students-sec-detail-2 ul li {
	color:#666666;
	padding:5px 0;
	display:inline-block;
	position:relative;
	padding-left:40px;	
	line-height:1.5;
}
.students-sec-detail-2 ul li:after {
	position:absolute;
	content:"";
	top:10px;
	left:0;
	width:15px;
	height:15px;
	border:2px solid #2abf46;
	border-radius:50%;
}
