/* react-player changes */
.react-player-wrapper {
  position: relative;
  min-height: 360px !important;
  padding-top: 32.43%; /* Player ratio: 100 / (1110 / 360) */
}
.react-player {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* react-pdf changes */
.pdf-viewer-doc .react-pdf__Page__canvas {
  margin-top: 10px !important;
  margin-bottom: 10px !important;
  width: 100% !important;
}
.pdf-viewer-doc .react-pdf__Page__textContent {
  padding: 10px !important;
  width: 100% !important;
}

/* bsn changes */
.modal-backdrop {
  z-index: 2001 !important;
}
.modal {
  max-height: 100%;
  overflow-y: auto !important;
  z-index: 2002;
}
.rc-time-picker-input {
  height: 30px !important;
  font-weight: 700 !important;
}
.rc-time-picker-panel {
  z-index: 2003 !important;
}
.rvtbl-cstm {
  display: table;
}
.timepicker-component {
  max-width: 200px !important;
}

/* ckEditor changes */
.ck-editor__editable_inline {
  min-height: 250px !important;
}

/* react-select changes */
.react-select-container {
  z-index: 1099;
}
.react-select-container-m1 {
  z-index: 1098;
}
.react-select-container-m2 {
  z-index: 1097;
}
.react-select-container-m3 {
  z-index: 1096;
}
.react-select-container-m4 {
  z-index: 1095;
}
.react-select-container-m5 {
  z-index: 1094;
}
.react-select__multi-value {
  background-color: #e6e6fa !important;
}

/* wufoo */
.wfc-parent {
  outline: 1px solid #696969;
  padding: 10px;
}

/* react-code-input */
/* see https://github.com/suweya/react-verification-code-input/blob/master/src/styles.css */
.rci-spacing > div > input {
  margin-right: 10px !important;
  width: 50px !important;
  border: solid 1px #a8adb7;
  border-radius: 0;
}
.rci-spacing > div > input:focus + input {
  border: solid 1px #a8adb7;
  border-radius: 0;
}
.rci-spacing > div > input:first-child {
  border: solid 1px #a8adb7;
  border-radius: 0;
}
.rci-spacing > div > input:last-child {
  border: solid 1px #a8adb7;
  border-radius: 0;
}

/* page loader */
.pageLoadingContainer {
  opacity: 0;
  transition: opacity 0.25s ease-out 0s;
  width: 0px !important;
  height: 0px !important;
  position: absolute;
  top: 0;
  z-index: 9999;
}
.pageLoadingContainerVis {
  opacity: 1;
  transition-delay: 1s;
  display: flex;
  width: 100% !important;
  height: 100% !important;
}
.pageLoading {
  visibility: hidden;
  transition: visibility 0.25s ease-out 0s;
}
.pageLoadingVis {
  visibility: visible;
  transition-delay: 1s;
  margin: auto;
}
.chunk-loader {
  min-height: 500px !important;
  padding-top: 230px;
  padding-bottom: 230px;
}

/* https://stackoverflow.com/a/34621519 */
@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px , 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

/* custom bootstrap */
.db-card-hdr, .fltr-card-hdr {
  color: #fff;
  background-color: #2abf46!important;
  border-color: #2abf46!important;
}
.term-card-hdr {
  color: #fff;
  background-color: #1e90ff!important;
  border-color: #1e90ff!important;
}
.input-group {
  border:1px solid #000;
}
.input-group-prepend {
  background-color: #007bff!important;
  padding-top: 3px!important;
  padding-bottom: 3px!important;
  padding-left: 12px!important;
  padding-right: 12px!important;
}
.input-group-text {
  padding: 0px!important;
  margin: 0px!important;
  color: #fff !important;
}
.progress-bar-title {
  position: absolute;
  text-align: center;
  line-height: 30px;
  overflow: hidden;
  color: #fff;
  right: 0;
  left: 0;
  top: 0;
}

/* misc. */
.outline {
  padding: 10px;
  outline: 1px solid #696969;
}
.outline-inner {
  padding: 20px;
  outline: 1px solid #DEB887;
}
.buttonAsLink {
  font-weight: 400;
  color: #007bff !important;
  background-color: transparent;
  padding: 0;
  cursor: pointer
}
.buttonAsLinkMenu {
  border: 0;
  cursor: pointer
}
.activebtnAsLinkMenu {
	color: #dc143c !important;
}
.pageTitle {
  font-family: 'Lucida Grande', 'Lucida Console', Arial, Helvetica, sans-serif;
  font-weight: 700;
}
.centerItem {
  margin-left: auto;
  margin-right: auto;
  text-align: center
}
.def-btn {
	font-size:18px;
	color:#fff !important;
	font-weight:700;
	background-color:#2abf46;
	border:2px solid #2abf46;
	padding:12px 50px;
	border-radius:5px;
	display:inline-block;
	transition:all 0.3s ease-in-out;
	cursor: pointer;
}
.cbr-logo {
  max-width: 200px !important;
  max-height: 40px !important;
}
.offline-warning {
  margin-left: 5px;
  margin-right: 5px;
}
.offline-warning-i {
  font-size: 32px !important;
}
.maint-msg-bg {
  background-color: red;
  padding-top: 5px;
  padding-bottom: 5px;
}
.maint-msg {
  color: #fff;
  font-weight: 700;
}
.card-hdr-hlp {
  color: #fff;
  text-shadow: 1px 1px 1px #ccc;
  font-size: 1.2em;
}
.tooltip-l-a {
  text-align: left !important;
}
.pending-email-input {
  padding: 5px 10px 30px 10px !important;
  background-color: #dcdcdc;
  color: #fff;
  font-weight: 700;
}
.ul-square-type {
  list-style-type: square;
}
.degrees-offered-list {
  max-height: 150px;
  overflow-y: auto;
}
.tasks-compl-bg {
  background-color: #006400;
}
.mgn-top {
  margin-top: 10px;
}
.boldLink {
	font-weight: 700 !important;
}
.hglt-clr {
  color:#2abf46;
}
.sml-tos-link {
  color: #868e96;
  text-decoration: underline;
}
.clm-coll-clr {
  color: #9f1d35;
}


.logo_icon_l {
  width: 50px !important;
  height: 50px !important;
  border-radius: 50%;
  background: #2abf46;
  text-align:center;
}
.logo_icon_l img, .logo_icon_r {
  padding-top: 6px;
  padding-bottom: 6px;
}
.info_text h4{
	font-size:20px;
}
.info_text h4 a{
	color:#1e242e;
	text-decoration: none; 
	-webkit-transition: all .3s;
	-moz-transition: all .3s;
	-ms-transition: all .3s;
	-o-transition: all .3s;
	transition: all .3s;
	position: relative;
	display: inline-block;
	padding-bottom: 5px;
}
.info_text h4 a:after{
	content: '';
  display: block !important;
  width: 0;
  position: absolute;
  height: 2px !important;
  background-color: #4798fa !important;
  left: 0;
  bottom: 0;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -moz-transition: all 0.4s;
}
.info_text h4:hover a,.info_text h4 a:hover{
	color:#4798fa
}
.info_text h4:hover a:after{
	width: 100%
}
.info_text h4 a:hover{
	color:#4798fa !important;
	-webkit-transition: all .3s;
  -moz-transition: all .3s;
  -ms-transition: all .3s;
  -o-transition: all .3s;
	transition: all .3s; 
}

.div-nl-1 {
  margin: auto 0 !important;
}
.div-nl-2 {
  padding-left: 10px !important;
  padding-right: 10px !important;
}
.div-nl-2i {
  margin: 0 auto !important;
  width: 75% !important;
}
.div-nl-3 {
  margin: auto 0 !important;
}

.stres-tip {
  font-size: small;
}

/* #####media queries#### */
/* till small */
@media (max-width:767px) {
  .offline-warning-i {
    font-size: 16px !important;
  }
  .def-btn {
    font-size: 12px !important;
    margin-top: 0px !important;
  }
  .mgn-btm {
    margin-bottom: 15px;
  }
  .cbr-logo {
    max-width: 100px !important;
  }
  .rc-time-picker-input {
    font-size: 16px !important;
  }
  .div-nl-1 {
    order: 1 !important;
    width: 100% !important;
    margin-top: 10px !important;
  }
  .div-nl-2 {
    order: 3 !important;
    width: 100% !important;
    margin-top: 10px !important;
  }
  .div-nl-2i {
    width: 100% !important;
  }
  .div-nl-3 {
    order: 2 !important;
    width: 100% !important;
    margin-top: 10px !important;
  }
}
/* above medium to large */
@media (min-width:768px) and (max-width:991px) {
	.mgn-btm {
    margin-bottom: 15px;
	}
}

@media (min-width: 480px) {
  .mgn-rgt {
    margin-right: 15px;
  }
}

/* till extra small */
@media (max-width:575px) {
  .rci-spacing > div > input {
    width: 40px !important;
  }
}

/* #####media queries#### */