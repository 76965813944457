.employe-service-bg {
	background-color:#f5f5f5;
	padding:40px 0;	
}
.employe-service-bg-2 {
	background-color:#f5f5f5;
	padding:40px 0;	
	margin-bottom: -20px !important;
}
.employe-service-sec h2 {
	font-size:35px;
	margin-top:0;
	line-height:1.3;
	font-weight:700;
	color:#1e242e;
}
.employe-service-sec p {
	font-size:20px;
}
.employe-service-sec h4 span {
  color:#2abf46;
}
.car-dev-detail .car-dav-body h2 {
	font-size:20px;
	font-weight:700;
	color:#000;
	margin-top:0;
	margin-bottom:15px;
}
.car-dev-detail .car-dav-body ul  {
	margin:0;
	padding:0;	
}
.car-dev-detail .car-dav-body ul li {
	list-style:none;
	margin-bottom:10px;
	font-size:16px;
	color:#666;
	position:relative;
	padding-left:25px;
	display:inline-block;
	line-height:1.5;	
}
.car-dev-detail .car-dav-body ul li i {
	position:absolute;
	top:5px;
	left:0;	
	color:#2abf46;
}
.colle-icon{
	text-align:center;
	display:inline-block;
}
.colle-icon i{
	font-size:60px;
	color:#2abf46;
	display:inline-block;
	margin-bottom:20px;
}
.colle-icon h3{
	font-size:20px;
	font-weight:bold;
	color:#1e242e;
}
.colle-btn {
	font-size:20px;
	font-weight:700;
	color:#000;
	margin-top:0;
	margin-bottom:15px;
}
.btn-cp-hl {
	font-size:18px;
	font-weight:700;
	color:#000;
}
.btn-cp-hl {
	color:#000;
}

/* #####media queries#### */
/* till extra small */
@media (max-width:575px) {
	.btn-cp {
		font-size:15px;
	}
}
/* #####media queries#### */