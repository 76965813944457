.about-sec {
	position:relative;	
	background-repeat:no-repeat;
}
.about-sec-bg-1 {
	background-image:url(../images/about-bg.jpg);
	background-size:cover;	
	padding: 0 !important;
	margin-top: -20px !important;
}
.about-sec-bg-1-t {
  width:100%;
	height:100%;
	background-color:rgba(30,36,46,0.6);
	padding:130px 0;
}
.about-sec h2 {
	font-size:45px;
	margin-top:0;
	margin-bottom:20px;
	line-height:1.3;
	font-weight:700;
	color:#1e242e;
}
.about-sec h3 {
	color:#fff;
	font-weight:700;
	font-size:45px;
}
.about-sec h4 span {
  color:#2abf46;
}
.about-sec-bg-2 {
	background-color:#f9f9f9;
	padding:40px 0;	
}

.md-half-w {
	width: 50% !important;
}

/* #####media queries#### */
/* till small */
@media (max-width:767px) {
	.about-sec {	
		background-position: 70% 0%;
	}
	.md-half-w {
    width: 100% !important;
  }
}
/* till large */
@media (max-width:1199px) {
	.about-sec {	
		background-position: 70% 0%;
	}
}
/* #####media queries#### */