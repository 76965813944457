.resource-menu {
	background-color:#f9f9f9;
	padding:30px;
}
.resource-menu h4 {
	font-size:20px;
	color:#2abf46;
	font-weight:700;	
	margin-bottom:15px;
}
.resource-menu ul {
	list-style:none;	
	padding-left:30px;
}
.resource-menu span.active {
	color: #dc143c !important;
}
.resource-menu li a {
	color:#2abf46 !important;	
	line-height:1.8;
	font-size:16px;
	transition:all 0.4s;
	cursor: pointer;
}
.resource-body ul  {
	margin:0;
	padding:0;	
}
.resource-body ul li {
	list-style:none;
	color:#666666;
	padding:5px 0;
	display:inline-block;
	position:relative;
	padding-left:40px;	
	line-height:1.5;
}
.resource-body ul li:after{
	position:absolute;
	content:"";
	top:10px;
	left:0;
	width:15px;
	height:15px;
	border:2px solid #2abf46;
	border-radius:50%;
}
.career-table table{
	width:100%;
	display:inline-block;
	margin-top:20px;
}
.career-table thead tr td, .career-table tr th{
	padding:15px;
	font-size:18px;
	color:#1e242e;
	color:#fff;
	background-color:#2abf46;
	border-right:1px solid #e9ecef;
}
.career-table tr td{
	padding:15px;	
	border:1px solid #e9ecef;
	font-size: 16px;
    line-height: 1.6;
}
.career-table tr:nth-child(3),
.career-table tr:nth-child(5),
.career-table tr:nth-child(7),
.career-table tr:nth-child(9){
	background-color:rgba(0,0,0,.05)	
}

.summary-table table{
	width:100%;
	display:inline-block;
	margin-top:20px;
}
.summary-table thead tr td, .summary-table tr th{
	padding:15px;
	font-size:18px;
	color:#1e242e;
	color:#fff;
	background-color:#2abf46;
	border-right:1px solid #e9ecef;
}
.summary-table tr td{
	padding:15px;	
	border:1px solid #e9ecef;
	font-size: 16px;
    line-height: 1.6;
}
.summary-table .striped-col {
	background-color:rgba(0,0,0,.05)	
}
.summary-table .striped-col a {
	font-weight: 700 !important;
}

.res-assmt-img {
	max-height: 240px !important;
}
.rst-bg {
	background-color: #fff !important;
}