.employers-sec {
	position:relative;	
	background-repeat:no-repeat;
}
.employers-sec-bg-1 {
	background-image:url(../images/employe-bg.jpg);
  background-size:cover;	
	padding: 0 !important;
	margin-top: -20px !important;
}
.employers-sec-bg-1-t {
  width:100%;
	height:100%;
	background-color:rgba(30,36,46,0.6);
  padding:130px 0;
}
.employers-sec h2 {
	font-size:36px;
	margin-top:0;
	margin-bottom:15px;
	font-weight:700;
	color:#1e242e;
}
.employers-sec h3 {
	color:#fff;
	font-weight:700;
	font-size:45px;
}
.employers-sec h4 span {
  color:#2abf46;
}
.employers-sec-detail-1 {
  background-color:#f9f9f9;
  padding: 40px;
}
.employers-sec-detail-1 h2 {
  font-size: 30px;
	color: #2abf46;
	font-weight: 700;
	margin-bottom: 20px;
}
.employers-sec-detail-1 ul li {
  font-size:16px;	
}
.employers-sec-detail-1 ul {
	margin:0;
	padding:0;	
}
.employers-sec-detail-1 ul li {
	list-style:none;	
}
.employers-sec-detail-1 ul li {
	color:#666666;
	padding:5px 0;
	display:inline-block;
	position:relative;
	padding-left:40px;	
	line-height:1.5;
}
.employers-sec-detail-1 ul li:after {
	position:absolute;
	content:"";
	top:10px;
	left:0;
	width:15px;
	height:15px;
	border:2px solid #2abf46;
	border-radius:50%;
}
.employers-sec-detail-2 ul{
	list-style:none;	
	margin-top:40px;
}
.employers-sec-detail-2 ul li{
	min-height:58px;	
	display:table;
	margin-bottom:30px;
}
.employers-sec-detail-2 ul li p{
	padding-left:70px;
	position:relative;	
	margin-bottom:0;
	display: table-cell;
    vertical-align: bottom;
	font-size:30px;
}	
.employers-sec-detail-2 ul li span{
	font-size:58px;
	font-weight:700;	
	position:absolute;
	line-height:1;
	z-index:5;
}
.employers-sec-detail-2 ul li span:after{
	content:"";
	position:absolute;
	bottom:10px;
	right:-20px;
	width:12px;
	height:12px;
	border-radius:50%;
	background-color:#2abf46;	
	z-index:5;
}
.employers-sec-bg-2 {
	background-color: #f9f9f9;
}

/* #####media queries#### */
/* till small */
@media (max-width:767px) {
	.employers-sec {	
		background-position: 70% 0%;
	}
}
/* till large */
@media (max-width:1199px) {
	.employers-sec {	
		background-position: 70% 0%;
	}
}
/* #####media queries#### */