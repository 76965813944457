.main-slider {
  width: 100%;
  margin-top: -20px !important;
  background-color: #20272f;
  color: #fff;
  position: relative;
}
.carousel-image img {
	max-height: 400px !important;
}
.carousel-legend {
  position: absolute;
  top: 50% !important;
  left: 300px !important;
}
.sldr-btn {
	border:2px solid #2abf46;
	background-color:transparent;
	color:#2abf46;
  margin-left:25px;
  margin-top: 15px;
  padding:12px 30px;
  cursor: pointer;
}
.sldr-btn i {
	margin-right:15px;
	vertical-align:middle;
}
.sldr-btn i:before {
	font-size:20px;
	margin-left:0;
}
.brand {
	border-bottom: 1px solid #ececec;
}
.brand h4 {
  font-size:20px;
  font-weight:700;
  color:#000;
}
.brand h4 span {
  color:#2abf46;
}
.blog-sec {
	display:inline-flex;
}	
.blog-sec h4 span {
  color:#2abf46;
}
.blog-sec .card {
  border: none !important;
}
.blog-detail {
	border:1px solid #dedfe1;
	height: 100% !important;
}
.blog-detail img {
	width: 100% !important;
	margin: 0px !important;
	padding: 0px !important;
}
.blog-detail .blog-content {
	padding:20px 15px;
} 
.blog-detail h2 {
	font-size:20px;
	font-weight:700;
	color:#000;
	margin-top:0;
	margin-bottom:15px;
	cursor: pointer;
}
.blog-detail p {
	line-height:1.5;
	margin-bottom:15px;
	min-height:144px;
}
.blog-detail {
	font-size:18px;
}
.blog-detail {
	margin-left:10px;
	font-size:22px;	
}
.blog-detail {
	vertical-align:unset;
}
.work-sec {
	background-color:#f5f5f5;
}
.work-sec h4 span {
  color:#2abf46;
}
.work-img img {
	display:inline-block;
}
.work-info {
	display:inline-flex;	
}
.work-detail {
	border:none;
	padding:15px 0 0;
}
.work-detail h2 strong {
	font-size:30px;
}
.work-detail h2 strong span {
	color:#2abf46;
	padding-right:10px;
}
.work-detail p {
	min-height:auto;
}
.banner {
	background-image:url(../images/banner.jpg);
	position:relative;
	background-repeat:no-repeat;
	padding:150px 0;
	background-attachment:fixed;
	background-position-x:right;
}
.bg-hover {
	position:absolute;
	width:100%;
	height:100%;
	top:0;
	left:0;
	background-color:rgba(30,36,46,0.8);
}
.banner h4 span {
  color:#2abf46;
}
.banner .heading h2,.banner .heading p {
	color:#fff;
}
.event {
	position:relative;
}
.event h4 span {
  color:#2abf46;
}
.event .heading p {
	max-width:none;	
}
.event > img {
	position:absolute;
	bottom:0;
  width:100%;
  margin-left: -15px !important;
}
.review-detail {
  width: 45% !important;
	padding:50px 30px !important;
	background-color:#fff;
  box-shadow:2.5px 4.33px 29px 0px rgba( 0, 0, 0, .1 );
  position:absolute;
	top:55px;
	left:0;
	z-index:9;
}
.review-body {
	position:relative;
}
.review-detail span {
  color: #2abf46;
  font-size: 19px;
	font-weight:300;
  margin-bottom: 5px;
  display: inline-block;
}
.review-detail h4 {
	font-weight:700;
	font-size:22px;
	line-height:1.5;
	color:#000;
	margin-bottom:20px;
}
.contact-sec p {
  margin-top:10px;
  display:block;
	max-width:none;	
}

/* #####media queries#### */
/* till extra small */
@media (max-width:575px) {
  .carousel-image img {
    max-width: 110px !important;
  }
  .carousel-legend {
    top: 10% !important;
    left: 130px !important;
  }
  .carousel-legend h2 {
    font-size: 1.20rem;
  }
  .sldr-btn {
    padding: 5px !important;
	}
}
/* till small */
@media (max-width:767px) {
  .event-bg {
    display: none;
  }
  .review-detail {
    width: 100% !important;
    padding: 15px !important;
    box-shadow: none;
    position:relative;
	}
	.banner {	
		background-position: 70% 0%;
	}
}
/* above extra small to small */
@media (min-width:576px) and (max-width:767px) {
  .sldr-btn {
    padding: 15px !important;
	}
}
/* #####media queries#### */